import styled from 'styled-components';

export const ContentWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 576px) {
    width: 540px;
  }

  @media (min-width: 768px) {
    width: 720px;
  }

  @media (min-width: 992px) {
    width: 960px;
  }

  @media (min-width: 1200px) {
    width: 1140px;
  }

  @media (min-width: 1400px) {
    width: 1200px;
  }
`;

export const Section = styled.div`
  background-color: ${({theme, bgColor}) => bgColor ? `${bgColor}` : theme.colors.white};
  padding: 3.5rem 0;

  ${({removeBtmPadding}) => removeBtmPadding && `
    padding-bottom: 0;
  `}

  @media(min-width: 992px) {
    padding: 5rem 0 5rem 0;
  }
`;