export default {
  colors: {
    primary: '#D02E26',
    secondary: '#262050',
    bg_topbar: '#355784',
    btn_color: '#355784',
    secondary_topbar: '#e2e2e2',
    primary_overlay: 'rgba(78,165,182, 0.5)',
    secondary_overlay: 'rgba(38,32,80, 0.5)',
    menu_overlay: 'rgba(53,87,132, 0.9)',
    white: '#ffffff',
  }
}